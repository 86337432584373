<template>
  <div>
    <b-tabs pills>
      <b-tab v-if="optionsLocal.tipoCalculoPosFixado" title="Recálculo de Parcelas" active>
        <b-card-text>
          <RecalculoDeParcelas />
        </b-card-text>
      </b-tab>

      <b-tab title="Parcelas Não Descontadas">
        <b-card-text>
          <ParcelasNaoDescontadas />
        </b-card-text>
      </b-tab>

      <b-tab title="Parcelas Descontadas">
        <b-card-text>
          <ParcelasDescontadas />
        </b-card-text>
      </b-tab>

      <b-tab title="Parcelas à Regularizar">
        <b-card-text>
          <ParcelasARegularizar />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ParcelasDescontadas from './component/parcelasDescontadas.vue'
  import ParcelasNaoDescontadas from './component/parcelasNaoDescontadas.vue'
  import ParcelasARegularizar from './component/parcelasARegularizar.vue'
  import RecalculoDeParcelas from './component/recalculoDeParcelas.vue'
  export default {
    components: {
      RecalculoDeParcelas,
      ParcelasARegularizar,
      ParcelasNaoDescontadas,
      ParcelasDescontadas,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        options: null,
        optionsLocal: {
          tipoCalculoPosFixado: false,
        },
      }
    },
    mounted() {},
    methods: {},
  }
</script>
