<template>
  <b-card-code no-body>
    <b-card-body>
      <!-- Pesquisa -->
      <b-row>
        <b-col md="2">
          <b-form-group label="Mês" label-for="mes">
            <b-form-select id="mes" :options="mesOpcoes" v-model="pesquisar.mes" />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group label="Ano" label-for="ano">
            <v-select-pt name="ano" v-model="pesquisar.exercicio" :options="anoOpcao" label="exercicio"></v-select-pt>
          </b-form-group>
        </b-col>

        <b-col sm="2">
          <b-form-group label="Índice de atualização" label-for="tipoIndice">
            <b-form-select
              name="tipoIndice"
              id="tipoIndice"
              v-model="pesquisar.tipoIndice"
              :options="indiceOpcoesSalvarIndice"
              :reduce="(option) => option.value"
              @change="atualizarIndice"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <!-- Índice Inflacionário -->
        <b-col sm="2" v-if="false">
          <b-form-group
            :label="tipoIndice ? 'Índice Inflacionário - ' + tipoIndice : 'Índice Inflacionário'"
            label-for="IndiceInflacionario"
          >
            <b-input-group>
              <b-form-input
                type="text"
                class="form-control-merge"
                name="IndiceInflacionario"
                id="taxaIndiceInflacionario"
                placeholder="Índice Inflacionário"
                v-model="taxaIndiceInflacionario"
                :disabled="true"
              />
              <b-input-group-append>
                <span class="input-group-text">%</span>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="auto">
          <b-button
            :disabled="isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 25px; width: 160px"
            @click="carregarGrid()"
          >
            {{ !isBusy ? 'Pesquisar' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>
      </b-row>

      <h3>Últimas Taxas</h3>
      <b-row>
        <b-col sm="2">
          <b-form-group label="Taxa IPCA" label-for="taxaIPCA">
            <b-input-group>
              <b-form-input type="text" name="taxaIPCA" id="taxaIPCA" v-model="taxaIndiceInflacionarioIPCA" :disabled="true" />
              <b-input-group-append>
                <span class="input-group-text">%</span>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Taxa INPC" label-for="taxaINPC">
            <b-input-group>
              <b-form-input type="text" name="taxaINPC" id="taxaINPC" v-model="taxaIndiceInflacionarioINPC" :disabled="true" />
              <b-input-group-append>
                <span class="input-group-text">%</span>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Taxa IGPM" label-for="taxaIGPM">
            <b-input-group>
              <b-form-input type="text" name="taxaIGPM" id="taxaIGPM" v-model="taxaIndiceInflacionarioIGPM" :disabled="true" />
              <b-input-group-append>
                <span class="input-group-text">%</span>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="auto">
          <b-button
            :disabled="items.length == 0 || isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 25px"
            @click="autenticaERecalculaParcelas"
          >
            {{ !isBusy ? 'Recalcular Parcela(s)' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>

        <b-col md="auto">
          <b-button
            :disabled="items.length == 0 || isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 25px"
            @click="autenticaEEnviaParaLancamento"
          >
            {{ !isBusy ? 'Enviar para Lançamentos' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card v-show="this.totalRows === 0">
      <h3 class="text-black text-center mb-2">Não foi encontrado nenhum lançamento para este mês</h3>
    </b-card>

    <!-- Tabela -->
    <b-table
      v-show="items.length > 0"
      id="tabelaLancamentos"
      small
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
      select-mode="multi"
      :selected-variant="'primary'"
      :selected.sync="selectedItems"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <!-- FIX mudar para abrir a modal com os lançamentos -->
          <b-dropdown-item @click="cadTomador(row.item.id)">
            <feather-icon icon="CheckCircleIcon" size="16" />
            <span class="align-middle ml-50">Gerar boleto</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <!-- <template #cell(check)="row">
        <b-form-checkbox
          v-if="row.item.statusDesconto != 'Descontado'"
          v-model="selectedItems"
          @change="adicionaContadorSelecionado(row.item)"
          :value="row.item.id"
          :name="'row-' + row.index"
        ></b-form-checkbox>
      </template> -->

      <template #cell(nomeCompleto)="row">
        {{ row.item.financeiro.segurado.tomador.nomeCompleto }}
      </template>

      <template #cell(cpf)="row">
        {{ row.item.financeiro.segurado.tomador.cpf }}
      </template>

      <template #cell(dataNascimento)="row">
        {{ formatarData(row.item.dataNascimento) }}
      </template>

      <template #cell(matricula)="row">
        {{ row.item.financeiro.segurado.matricula }}
      </template>

      <template #cell(categoria)="row">
        {{ row.item.financeiro.segurado.situacaoPrevidenciaria }}
      </template>

      <template #cell(valorTotalParcela)="row">
        {{ formatarValor(row.item.valor) }}
      </template>

      <template #cell(descontoRealizado)="row">
        {{ row.item.descontoRealizado ? formatarValor(row.item.descontoRealizado) : formatarValor(0) }}
      </template>

      <template #cell(valorAPagar)="row">
        {{ formatarValor(row.item.valor - row.item.descontoRealizado) }}
      </template>

      <template #cell(valorSemCorrecao)="row">
        {{ formatarValor(row.item.valorSemCorrecao) }}
      </template>

      <template #cell(valorComCorrecao)="row">
        {{ formatarValor(row.item.valorComCorrecao) }}
      </template>

      <template #cell(contratoNumero)="row">
        {{ row.item.financeiro.codigo }}
      </template>

      <template #cell(periodo)="row">
        {{ String(row.item.competencia).substring(4, 6) + '/' + String(row.item.competencia).substring(0, 4) }}
      </template>

      <template #cell(status)="row">
        <b-badge :variant="statusMap[row.item.statusDesconto][1]">
          {{ statusMap[row.item.statusDesconto][0] }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card-code>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import moment from 'moment'
  import { formatarData, formatarParaNumber, formatarValor } from '@/libs/utils'
  import modalCadastroTomador from '@/views/components/Tomador/modalCadastroTomador.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import lancamentoQuitacao from '../../lancamento/lancamentoQuitacao.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import ExportarPDFParcelasPaisagem from '../relatorios/ExportarPDFParcelasPaisagem.vue'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      modalCadastroTomador,
      lancamentoQuitacao,
      ToastificationContent,
      formatarValor,
      formatarData,
      AutenticaSenha,
      ExportarPDFParcelasPaisagem,
      formatarParaNumber,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        formatarData,
        formatarValor,
        formatarParaNumber,
        tipoIndice: '',
        statusValor: undefined,
        statusMap: {
          Descontado: ['Parcela Descontada', 'success'],
          Rejeitada: ['Parcela Rejeitada', 'danger'],
          PagaParcialmente: ['Paga Parcialmente', 'warning'],
          NaoDescontada: ['Não Descontada', 'secondary'],
        },
        statusOpcao: [
          { value: 'Descontado', text: 'Parcelas Descontadas' },
          { value: 'Rejeitada', text: 'Parcelas Rejeitadas' },
          { value: 'PagaParcialmente', text: 'Parcelas Pagas Parcialmente' },
          { value: 'NaoDescontada', text: 'Parcelas Não Descontadas' },
        ],
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        orgaoOpcao: [],
        anoOpcao: [],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Consolidado', text: 'Consolidado' },
        ],
        pesquisar: {
          perPage: 10,
          mes: null,
          exercicio: null,
          orgao: null,
          tipoIndice: null,
        },
        indiceOpcoesSalvarIndice: [
          { value: 'IPCA', text: 'IPCA' },
          { value: 'INPC', text: 'INPC' },
          { value: 'IGPM', text: 'IGPM' },
        ],
        taxaIndiceInflacionario: 0,
        taxaIndiceInflacionarioIPCA: 0,
        taxaIndiceInflacionarioINPC: 0,
        taxaIndiceInflacionarioIGPM: 0,
        taxaEmprestimoMensal: 0,
        required,
        number: 0.0,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        mode: '',
        sortDirection: 'asc',
        baseFields: [],
        fields: [
          // { key: 'check', label: 'Selecionar' },
          { key: 'parcelaAtual', label: 'Nº da Parcela' },
          { key: 'matricula', label: 'Nº Matrícula' },
          { key: 'nomeCompleto', label: 'Nome', sortable: true },
          { key: 'cpf', label: 'CPF', sortable: true },
          { key: 'contratoNumero', label: 'Nº Do Contrato', sortable: true },
          { key: 'valorSemCorrecao', label: 'Valor S/ Correção', sortable: true },
          { key: 'valorComCorrecao', label: 'Valor C/ Correção', sortable: true },
        ],
        borderless: true,
        statusGRPE: [
          {
            GeradoGRPE: 'success',
            NaoGeradoGRPE: 'warning',
            null: 'warning',
          },
        ],
        statusLancamento: [
          {
            Lancado: 'success',
            null: 'warning',
            NaoLancado: 'warning',
          },
        ],
        items: [],
        finanitems: [],
        lancamento: [],
        parc: [],
        prop: [],
        vouPesquisar: false,
        contrato: null,
        usersUrl: 'https://jsonplaceholder.typicode.com/users',
        photosUrl: 'https://jsonplaceholder.typicode.com/photos',
        acesso: {},
        cadastroTomador: [],
        selectedItems: [],
        habilitarGRPE: false,
        somatoriaValores: 0,
        desabilitarLancGRPE: false,

        // Relatório PDF
        parametroPDF: {
          dados: [],
          colunas: [],
        },
        fieldsPDF: [
          { key: 'parcelaAtual', label: 'Nº da Parcela' },
          { key: 'matricula', label: 'Nº Matrícula' },
          { key: 'nomeCompleto', label: 'Nome', sortable: true },
          { key: 'cpf', label: 'CPF', sortable: true },
          { key: 'contratoNumero', label: 'Nº Do Contrato', sortable: true },
          { key: 'valorAPagar', label: 'Valor a Pagar', sortable: true },
          { key: 'status', label: 'Status', sortable: true },
        ],
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    async mounted() {
      this.tipoIndice = this.userData.Instituto.tipoIndiceSelecionado
      this.pesquisar.tipoIndice = this.tipoIndice
      await this.carregaAno()
      await this.carregaIndice()
      this.atualizarIndice(this.tipoIndice)
    },
    methods: {
      atualizarIndice(newValue) {
        const taxas = {
          IPCA: this.taxaIndiceInflacionarioIPCA,
          INPC: this.taxaIndiceInflacionarioINPC,
          IGPM: this.taxaIndiceInflacionarioIGPM,
        }

        this.taxaIndiceInflacionario = taxas[newValue] || 0

        this.items = []
        this.totalRows = 0
      },
      async carregarGrid() {
        try {
          this.isBusy = true
          this.somatoriaValores = 0
          const parcelas = await useJwt.post(`lancamento/listParaRecalculo`, {
            mes: this.pesquisar.mes,
            ano: this.pesquisar.exercicio,
            orgaoId: this.pesquisar.orgao,
            tomadorNome: this.pesquisar.nome,
            institutoId: this.userData.institutoSelecionado,
            tipoIndice: this.pesquisar.tipoIndice,
          })

          this.isBusy = false
          this.items = parcelas.data
          this.totalRows = parcelas.data.length

          this.somatoriaValores = this.items.reduce((acc, item) => {
            return acc + (formatarParaNumber(item.valor) - formatarParaNumber(item.descontoRealizado))
          }, 0)

          this.linhasTabela = this.items

          let competencia = ''
          let orgaoNome = ''

          if (this.pesquisar.mes) {
            competencia =
              String(this.mesOpcoes.find((item) => item.value == this.pesquisar.mes).text) +
              '/' +
              String(this.pesquisar.exercicio)
          }
          if (this.pesquisar.orgao) {
            orgaoNome = this.orgaoOpcao.find((item) => item.id == this.pesquisar.orgao).nome
          }

          // Monta variável global de relatório PDF
          this.parametroPDF = {
            dados: this.linhasTabela,
            colunas: this.fieldsPDF,
            institutoNome: this.userData.Instituto.nome,
            competencia: competencia,
            orgao: orgaoNome,
            titulo: 'Parcelas à Regularizar',
          }
        } catch (error) {
          console.error('Erro: ' + error)
        }
      },
      async carregaAno() {
        const mes = moment(new Date(), 'MM').add(1, 'M').format('M')
        const ano = moment(new Date(), 'YYYY').add(1, 'M').format('YYYY')
        this.pesquisar.mes = mes
        this.pesquisar.exercicio = ano
        let anoAtual = 2019

        let listaAnos = []
        for (let i = 0; i < 30; i++) {
          let anoIteracao = String(anoAtual + i)
          listaAnos.push(anoIteracao)
        }

        listaAnos = listaAnos.sort((a, b) => a - b)

        this.anoOpcao = listaAnos
      },
      autenticarMethod(data) {
        if (data) {
          if (this.mode == 'recalcularParcelas') this.recalculaParcelas()
          if (this.mode == 'enviarParaLancamentos') this.enviarParaLancamento()
        }
      },
      autenticaEBaixa() {
        this.mode = 'baixa'
        this.$refs['modal-auth-senha'].show()
      },
      autenticaERecalculaParcelas() {
        if (!this.taxaIndiceInflacionario) {
          this.$message.error('Índice mensal não encontrado!')
          return
        }

        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente RECALCULAR o(s) lançamento(s)?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.mode = 'recalcularParcelas'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticaEEnviaParaLancamento() {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente enviar os dados para o lançamento?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.mode = 'enviarParaLancamentos'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      enviarParaLancamento() {
        this.$refs['modal-auth-senha'].hide()
        let temItemSemCorrecao = this.items.find((item) => !item.valorComCorrecao)
        if (temItemSemCorrecao) {
          this.$message.error('Existem parcelas sem correção!')
          return
        }

        this.isBusy = true
        let parametros = {
          mes: this.pesquisar.mes,
          ano: this.pesquisar.exercicio,
          listaDeParcelas: this.items,
          taxaIndiceInflacionario: this.taxaIndiceInflacionario,
          tipoIndice: this.pesquisar.tipoIndice,
        }

        useJwt
          .post('lancamento/alterarStatusRecalculo', parametros)
          .then((response) => {
            this.isBusy = false
            this.items = response.data
            this.carregarGrid()
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
            this.$message.error('Erro ao recalcular parcelas!')
          })
      },
      recalculaParcelas() {
        this.$refs['modal-auth-senha'].hide()
        this.isBusy = true

        const PARCELAS_SEM_RECALCULO = this.items.filter((item) => !item.realizouRecalculo)

        if (!PARCELAS_SEM_RECALCULO.length) {
          this.$message.error('Não é possível recalcular uma parcela já recalculada!')
          this.isBusy = false
          return
        }

        let parametros = {
          mes: this.pesquisar.mes,
          ano: this.pesquisar.exercicio,
          listaDeParcelas: this.items,
          taxaIndiceInflacionario: this.taxaIndiceInflacionario,
          tipoIndice: this.pesquisar.tipoIndice,
        }

        useJwt
          .post('lancamento/recalcularListaDeParcelas', parametros)
          .then((response) => {
            this.isBusy = false
            this.items = response.data
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
            this.$message.error('Erro ao recalcular parcelas!')
          })
      },
      async carregaIndice() {
        await useJwt
          .pesquisar('cadastro/instituto/GetpesquisarIndices', { tipoIndice: `IPCA` })
          .then((response) => {
            this.taxaIndiceInflacionarioIPCA = response.data.taxa ?? 0
          })
          .catch((error) => {
            console.error(error)
          })

        await useJwt
          .pesquisar('cadastro/instituto/GetpesquisarIndices', { tipoIndice: `INPC` })
          .then((response) => {
            this.taxaIndiceInflacionarioINPC = response.data.taxa ?? 0
          })
          .catch((error) => {
            console.error(error)
          })

        await useJwt
          .pesquisar('cadastro/instituto/GetpesquisarIndices', { tipoIndice: `IGPM` })
          .then((response) => {
            this.taxaIndiceInflacionarioIGPM = response.data.taxa ?? 0
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style lang="scss">
  #tabelaLancamentos > thead > tr > th {
    // padding: 0.52rem 0.7rem;
    // padding-top: 0.52rem;
    // padding-right: 0.7rem;
    // padding-bottom: 0.52rem;
    // padding-left: 0.7rem;
    vertical-align: middle !important;
    text-align: center;
  }

  #tabelaLancamentos > tbody > tr > td {
    // padding: 0.52rem 0.7rem;
    // padding-top: 0.52rem;
    // padding-right: 0.7rem;
    // padding-bottom: 0.52rem;
    // padding-left: 0.7rem;
    vertical-align: middle !important;
    text-align: center;
  }
</style>
